import React from 'react';
import ProfilePicture from '../../../Custom UI/ProfilePicture/ProfilePicture';
import LabeledTextInput from '../../../Custom UI/LabeledInputs/LabeledTextInput/LabeledTextInput';
import ChangePasswordModal from '../../../Modals/ChangePasswordModal/ChangePasswordModal';
import './ManageSettings.scss';
import {NOTIFY_OPTS} from 'constants/Notifiers';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Translation, withTranslation} from "react-i18next";
import i18n from "../../../i18n";
import i18next from "i18next";
import AdminHistory from "../../../containers/AdminHistoryContainer";
import ManageUserSettings from "../ManageUserSettings/ManageUserSettings";
import SecondaryHeader from "../../../Custom UI/Headers/SecondaryHeader/SecondaryHeader";
import TabBar from "../../../Custom UI/TabBar/TabBar";
import LabeledDelayedInput from "../../../Custom UI/LabeledInputs/LabeledDelayedInput/LabeledDelayedInput";
import LabeledDropDown from "../../../Custom UI/LabeledInputs/LabeledDropDown/LabeledDropDown";
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import {registerLocale, setDefaultLocale} from "react-datepicker";
import {roleForCompany, hsFeatureAvailableForCompany} from "../../../Helpers/CompanyHelpers";
import {isoDateTimeFormat, dateTimeFormat} from "../../../Helpers/DateHelpers";
import moment from "moment-timezone";
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import LabeledDatePicker from "../../../Custom UI/LabeledInputs/LabeledDatePicker/LabeledDatePicker";
import HsFeaturesList from "../../../Lists/HsFeaturesList/HsFeaturesList";
import {titleAndDescriptionForAdminRole} from "../../../Helpers/AdminHelpers";
import CloseStudy from './CloseStudy';
import {errorMessageFromServerError} from "../../../Helpers/Helpers";

registerLocale('de', de);
registerLocale('es', es);
registerLocale('fr', fr);
registerLocale('it', it);

const notifyUpdateError = (aField, aError) =>  toast(i18next.t('FIELD_UPDATE_ERROR', {aField:aField, aError:aError}), NOTIFY_OPTS.autoCloseFiveSeconds);
const notifyUpdateSuccess = (aField) =>  toast(<Translation>{ (t, {i18n}) => t('FIELD_UPDATED', {field:aField}) }</Translation>, NOTIFY_OPTS.autoCloseThreeSeconds);

class ManageSettings extends React.Component {
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      tabBarKey:'MY_PROFILE',
      timeoutError:'',
      changePasswordIsOpen:false,
      currentDropDownLanguage:'en'
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleCompanyLocale = this.handleCompanyLocale.bind(this);
    this.handleStudyNameUpdate = this.handleStudyNameUpdate.bind(this);
    this.dropDownLanguageChanged = this.dropDownLanguageChanged.bind(this);
  }
  
  componentDidMount(){
    setTimeout(function(){ this.loadSystemLanguage(); }.bind(this), 100);
  }
  
  // Methods
  
  loadSystemLanguage() {
    this.setState({currentDropDownLanguage:i18n.language});
  }
  
  dropDownLanguageChanged(aOptionValue){
    this.setState({currentDropDownLanguage:aOptionValue},
      () => {
        this.handleSave({key:'locale', value:aOptionValue});
        setDefaultLocale(aOptionValue);
        i18n.changeLanguage(this.state.currentDropDownLanguage).then(r => console.log("Current Language:",  this.state.currentDropDownLanguage));
        moment.locale(this.state.currentDropDownLanguage);
      });
  };
  
  handleSave({key, value}){
    this.props.updateCurrentUser({id:this.props.user.id, [key]:value}).then(newResult => {
      if(newResult === true){
        notifyUpdateSuccess(i18next.t('' + key.toUpperCase())) && this.props.loadUser();
      }
    }, (newError) => {
    
    });
  }
  
  fileChangedHandler = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    
    reader.onload = () => {
      this.props.updateCurrentUserImage(file).then((newResult) => this.props.loadUser(), (newError) => {
      
      });
    }
  };
  
  logoChangedHandler = (aEvent) => {
    const file = aEvent.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    
    reader.onload = () => {
      this.props.updateCompanyLogoImage(file, this.props.company.theme.id).then((newResult) => this.props.loadCompany(), (newError) => {
      
      });
    };
  };
  
  handleCompanyLocale = (aOption) => {
    const {company, updateCompany} = this.props;
    
    if(aOption && company && updateCompany){
      updateCompany(company.id, company.theme.headline, aOption.value, company.last_enrolment_date).then((newResult) => {
        notifyUpdateSuccess(i18next.t('LOCALE'));
      }, (newError) => {
        notifyUpdateError(i18next.t('LOCALE'), errorMessageFromServerError(newError));
      });
    }
  };
  
  handleCompanyLastEnrolmentDate = (aDate) => {
    const {company, updateCompany} = this.props;
    
    if(aDate && company && updateCompany){
      updateCompany(company.id, company.theme.headline, company.locale, aDate.format(isoDateTimeFormat)).then((newResult) => {
        notifyUpdateSuccess(i18next.t('LAST_ENROLMENT_DATE'));
      }, (newError) => {
        notifyUpdateError(i18next.t('LAST_ENROLMENT_DATE'), errorMessageFromServerError(newError));
      });
    }
  };
  
  handleStudyNameUpdate = (aEvent) => {
    const {company, updateCompany} = this.props;
    
    if(aEvent && company && updateCompany){
      let locale = 'en';
      
      if(company && company.locale){
        locale = company.locale;
      }
      updateCompany(company.id, aEvent.target.value, locale, company.last_enrolment_date).then((newResult) => {
        notifyUpdateSuccess(i18next.t('STUDY_NAME'));
      }, (newError) => {
        notifyUpdateError(i18next.t('STUDY_NAME'), errorMessageFromServerError(newError));
      });
    }
  };
  
  updateHSCTimeoutInMinutes = (aMinutes) => {
    const {company, updateCompany} = this.props;
    const max = 20160;
    const min = 30;
    
    this.setState({timeoutError:''}, () => {
      if(aMinutes >= min){
        if(aMinutes <= max){
          if(company && updateCompany){
            updateCompany(company.id, company.theme.headline, company.locale, company.last_enrolment_date, company.payment_type, aMinutes).then((newResult) => {
              notifyUpdateSuccess(i18next.t('HSC_TIMEOUT_IN_MINUTES'));
            }, (newError) => {
              let error = errorMessageFromServerError(newError);
              this.setState({timeoutError:error})
              notifyUpdateError(i18next.t('HSC_TIMEOUT_IN_MINUTES'), error);
            });
          }
        }
        else{
          this.setState({timeoutError:i18next.t('NUMBER_CANNOT_BE_HIGHER_THAN', {aNumber:max})});
        }
      }
      else{
        this.setState({timeoutError:i18next.t('NUMBER_CANNOT_BE_LOWER_THAN', {aNumber:min})});
      }
    });
  };
  
  // Render
  
  render() {
    const {user, company, loadUser, loadCompany, findUserById, updateCurrentUser, updateCompanyHSFeature, logout, closeStudy, studyClosed} = this.props;
    const {tabBarKey, timeoutError, changePasswordIsOpen, currentDropDownLanguage} = this.state;
    
    const currentRole = roleForCompany(company);
    const roleTitleAndDescription = titleAndDescriptionForAdminRole(currentRole);
    const studyProhibitions = ['physician', 'admin_level_1', 'admin_level_8'];
    const heFeaturePermissions = ['admin', 'admin_level_4'];
    const papertrailPermissions = ['admin'];
    const closeStudyPermissions = ['admin', 'admin_level_4'];
    
    let english = (i18next.t('ENGLISH_WITH_FLAG'));
    let italian = (i18next.t('ITALIAN_WITH_FLAG'));
    let french = (i18next.t('FRENCH_WITH_FLAG'));
    let german = (i18next.t('GERMAN_WITH_FLAG'));
    let spanish = (i18next.t('SPANISH_WITH_FLAG'));
    let availableLanguages = [
      {languageString:english, languageCode:'en'},
      {languageString:italian, languageCode:'it'},
      {languageString:french, languageCode:'fr'},
      {languageString:german, languageCode:'de'},
      {languageString:spanish, languageCode:'es'}
    ];
    
    let languageOptions = [];
    
    if(availableLanguages){
      availableLanguages.map((aLanguage, index) => (
        languageOptions.push({value:aLanguage.languageCode, label:aLanguage.languageString})
      ));
    }
    
    let relativeURL = window.location.pathname;
    let userId = (user !== null ? user.id : null);
    
    if(relativeURL.length > 0){
      let trimmedURL = relativeURL.replace('/manage/settings/', '');
      
      if(trimmedURL.includes('/manage/settings') === false){
        userId = parseInt(trimmedURL, 10);
      }
    }
    
    let tabBarTabsArray = [{key:'MY_PROFILE'}];
    
    if(!studyProhibitions.includes(currentRole) && !studyClosed){
      tabBarTabsArray.push({key:'MY_PROJECT_STUDY'});
    }
    if(heFeaturePermissions.includes(currentRole) && !studyClosed){
      tabBarTabsArray.push({key:'HS_FEATURES'});
    }
    if(papertrailPermissions.includes(currentRole)){
      tabBarTabsArray.push({key:'MY_AUDIT_LOG'});
    }
    
    return (
      <div className="main-panel-inner-container">
        <SecondaryHeader title={<Translation>{ (t, {i18n}) => t('SETTINGS') }</Translation>} />
        
        <TabBar tabBarTabsArray={tabBarTabsArray}
                initialSelectedTabKey={tabBarKey}
                selectedTabKeyChanged={(aKey) => this.setState({tabBarKey:aKey})}
        />
        
        {tabBarKey === 'MY_PROFILE' ?
          <div className="manage-settings-container">
            {(userId === null) || (user && user.id === userId) ?
              <div>
                <div className="my-profile-row">
                  <div className="profile-pic-container">
                    <div style={{width:'150px'}}>
                      <ProfilePicture imageId={(user && user.image_id) || null}
                                      width={100}
                                      height={100}
                                      label={<Translation>{ (t, { i18n }) => t('PROFILE_PICTURE_HEADER') }</Translation>}
                                      className='profile-picture cell'
                                      style={{ border: 'none' }}
                                      disabled={studyClosed}
                                      borderStyle={{ border: 'none' }}
                                      editAction={this.fileChangedHandler}
                      />
                    </div>
                    
                    <div style={{width:'100%'}}>
                      <div className="profile-input-container">
                        <LabeledDelayedInput className="manage-settings-profile-first-name"
                                             label={<Translation>{ (t, {i18n}) => t('FIRST_NAME_PROPER_CAPITALIZED') }</Translation>}
                                             value={user && user.first_name}
                                             disabled={this.state.changePasswordIsOpen || studyClosed}
                                             handleSave={(aEvent) => this.handleSave({key:'first_name', value:aEvent.target.value})}
                        />
                        
                        <LabeledDelayedInput className="manage-settings-profile-last-name"
                                             value={user && user.last_name}
                                             disabled={this.state.changePasswordIsOpen || studyClosed}
                                             handleSave={(aEvent) => this.handleSave({key:'last_name', value:aEvent.target.value})}
                                             label={<Translation>{ (t, {i18n}) => t('LAST_NAME_PROPER_CAPITALIZED') }</Translation>}
                        />
                      </div>
                      
                      <LabeledTextInput className="manage-settings-profile-email"
                                        type="email"
                                        value={user && user.email}
                                        disabled={true}
                                        label={<Translation>{ (t, {i18n}) => t('EMAIL_ADDRESS') }</Translation>}
                      />
                    </div>
                  </div>
                  
                  <div className="settings-input-container">
                    <div className="settings-edit-container">
                      <LabeledDropDown className="settings-text-input-edit-language"
                                       label={<Translation>{ (t, { i18n }) => t('SELECT_SYSTEM_LANGUAGE') }</Translation>}
                                       value={currentDropDownLanguage && languageOptions.filter(function(option){
                                         return option.value === currentDropDownLanguage;
                                       })}
                                       disabled={changePasswordIsOpen}
                                       options={languageOptions}
                                       handleSave={(aOption) => this.dropDownLanguageChanged(aOption.value)}
                      />
                      
                      <LabeledTextInput className="settings-text-input-edit-password"
                                        type="password"
                                        label={<Translation>{ (t, {i18n}) => t('PASSWORD') }</Translation>}
                                        value={"••••••••"}
                                        disabled={true}
                                        editButtonClicked={() => this.setState({changePasswordIsOpen:true})}
                      />
                    </div>
                  </div>
                  
                  {currentRole ?
                    <div className="manage-settings-admin-description">
                      <b>
                        <Translation>{(t, {i18n}) => t(roleTitleAndDescription.title)}</Translation>
                      </b>
                      
                      <Translation>{(t, {i18n}) => t(roleTitleAndDescription.description)}</Translation>
                    </div>
                    :
                    null
                  }
                </div>
                
                <ChangePasswordModal isOpen={changePasswordIsOpen}
                                     userId={user ? user.id : undefined}
                                     loadUser={loadUser}
                                     closeModal={() => this.setState({changePasswordIsOpen:false})}
                                     saveButtonTitle={i18next.t('UPDATE')}
                                     updateCurrentUser={updateCurrentUser}
                                     showSaveButtonOnRight={true}
                                     requiresCurrentPassword={true}
                />
              </div>
              :
              <ManageUserSettings userId={userId}
                                  findUserById={findUserById}
              />
            }
          </div>
          :
          null
        }
        
        {tabBarKey === 'MY_PROJECT_STUDY' ?
          <div className="manage-settings-container">
            <div className="profile-pic-container">
              <ProfilePicture className="profile-picture cell"
                              aImageUrl={(company && company.theme && company.theme.logo_url) ? company.theme.logo_url : null}
                              width={100}
                              height={100}
                              label={<Translation>{ (t, {i18n}) => t('STUDY_LOGO_LABEL') }</Translation>}
                              style={{border:'none'}}
                              borderStyle={{border:'none'}}
                              editAction={this.logoChangedHandler}
              />
              
              <div className="manage-settings-study-container">
                <LabeledDelayedInput className="settings-text-input-long-local-name"
                                     name="study_name"
                                     value={company && company.theme && company.theme.headline}
                                     handleSave={this.handleStudyNameUpdate}
                                     label={<Translation>{ (t, {i18n}) => t('MY_PRODUCT_OR_STUDY_LABEL') }</Translation>}
                                     descriptionLabel={<Translation>{ (t, {i18n}) => t('STUDY_NAME_INFO_TEXT') }</Translation>}
                                     placeholder={i18n.t('MY_PRODUCT_OR_STUDY_LABEL')}
                />
                
                <LabeledDropDown className="settings-text-input-long-local"
                                 label={<Translation>{ (t, {i18n}) => t('LOCALE') }</Translation>}
                                 value={company && company.locale && languageOptions.filter(function(option){
                                   return option.value === company.locale;
                                 })}
                                 options={languageOptions}
                                 handleSave={this.handleCompanyLocale}
                />
                
                <LabeledDatePicker className="manage-settings-last-enrolment-date-input"
                                   label={<Translation>{ (t, {i18n}) => t('LAST_ENROLMENT_DATE') }</Translation>}
                                   value={company && company.last_enrolment_date}
                                   minDate={moment()}
                                   infoBlurb={<Translation>{(t, {i18n}) => t('LAST_ENROLMENT_DATE_EXPLANATION')}</Translation>}
                                   dateFormat={dateTimeFormat}
                                   handleSave={this.handleCompanyLastEnrolmentDate}
                                   isClearable={true}
                                   showTimeSelect={true}
                                   timeCaption={<Translation>{ (t, {i18n}) => t('TIME_PROPER_CAPITALIZED') }</Translation>}
                />
                
                {['admin', 'admin_level_4'].includes(currentRole) ?
                  <LabeledDelayedInput className="manage-settings-number-input-long"
                                       type="number"
                                       label={i18next.t('HSC_TIMEOUT_IN_MINUTES')}
                                       value={company && company.hsc_timeout_minutes ? company.hsc_timeout_minutes : '20160'}
                                       infoBlurb={i18next.t('HSC_TIMEOUT_IN_MINUTES_DESCRIPTION')}
                                       minLength={1}
                                       handleSave={(aEvent) => this.updateHSCTimeoutInMinutes(Number(aEvent.target.value))}
                                       placeholder={i18next.t('HSC_TIMEOUT_IN_MINUTES')}
                                       errorMessage={timeoutError}
                  />
                  :
                  null
                }
              </div>
            </div>
            
            {hsFeatureAvailableForCompany(company, 'Close Study') && closeStudyPermissions.includes(currentRole) ?
              <CloseStudy logout={logout}
                          closeStudy={closeStudy}
                          studyClosed={company?.status === 'closed'}
              />
              :
              null
            }
          </div>
          :
          null
        }
        
        {tabBarKey === 'HS_FEATURES' ?
          <HsFeaturesList className="manage-settings-hs-features-container"
                          company={company}
                          loadCompany={loadCompany}
                          updateCompanyHSFeature={updateCompanyHSFeature}
          />
          :
          null
        }
        
        {tabBarKey === 'MY_AUDIT_LOG' ?
          <AdminHistory userId={userId}
                        currentRole={currentRole}
          />
          :
          null
        }
      </div>
    );
  }
}
export default withTranslation()(ManageSettings);
