import React from 'react';
import './PatientProfileToolViz.scss'
import EntryIndicatorChart from "../charts/EntryIndicatorChart/EntryIndicatorChart";
import StackedBarChart from "../charts/StackedBarChart/StackedBarChart";
import BarChart from "../charts/BarChart/BarChart";
import LineChart from "../charts/LineChart/LineChart";
import ReminderChartGroup from "../charts/ReminderChartGroup";
import ChartTitle from "../charts/ChartTitle";
import {ObjectiveConstants} from "../../../constants/ObjectiveConstants";
import {QuestConstants} from "../../../constants/QuestConstants";
import { TOOLTIP_TYPES } from 'constants/ChartConstants';

const collapseUpButton = '/imgs/app/collapse-up-button.svg';
const expandDownButton = '/imgs/app/expand-down-button.svg';

export default class PatientProfileToolViz extends React.Component{
  
  // Init
  
  constructor(props){
    super(props);
    this.state = {
      collapsed: this.props.emptyData,
      chart: null,
      objective: null
    };
    this.toggleCollapsed = this.toggleCollapsed.bind(this);
  }

  componentDidMount(){
    let {quest, to, from, patient} = this.props;
    let startDate = from.toDate();
    let endDate = to.toDate();
    
    if(quest != null && patient != null){
      let objective = quest.objectives.find((objective) => objective.is_primary_hsc_visualization);

      if((objective === undefined || objective === null) && quest.objectives.length > 0){
        objective = quest.objectives[0];
      }
      else{
        objective = {id: null};
      }
      if(quest.hsc_visualization && quest.hsc_visualization === QuestConstants.HSCVisualizationReminderChart){
        this.props.getFullQuest(quest.id, patient.id).then(data => {
          this.setState({chart: this.buildReminderChartGroups(data.data.data.quest, startDate, endDate), objective: objective});
        });
      }
      else{
        this.setState({chart: this.buildChartComponent(quest, startDate, endDate), objective: objective});
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.quest != null && this.props.to != null && this.props.from != null && this.props.patient != null){
      if(prevProps.quest == null || prevProps.quest.id !== this.props.quest.id){
        let {quest, to, from} = this.props;
        let startDate = from.toDate();
        let endDate = to.toDate();
        let objective = quest.objectives.find((objective) => objective.is_primary_hsc_visualization);

        if((objective === undefined || objective === null) && quest.objectives.length > 0){
          objective = quest.objectives[0];
        }
        else{
          objective = {id: null};
        }
        if(quest.hsc_visualization && quest.hsc_visualization === QuestConstants.HSCVisualizationReminderChart){
          this.props.getFullQuest(quest.id, this.props.patient.id).then(data => {
            this.setState({chart: this.buildReminderChartGroups(data.data.data.quest, startDate, endDate), objective: objective});
          });
        }
        else {
          this.setState({chart: this.buildChartComponent(quest, startDate, endDate), objective: objective});
        }
      }
    }
  }
  
  // Methods

  toggleCollapsed(){
    let newState = this.state;
    newState.collapsed = !this.state.collapsed;
    this.setState(newState);
  }

  buildChartComponent(quest, startDate, endDate){
    let visualization = quest.hsc_visualization;
    let component = null;

    if(visualization === undefined || visualization === null){
      visualization = QuestConstants.HSCVisualizationEntryIndicator;
    }
    if(visualization === QuestConstants.HSCVisualizationReminderChart){
    }
    else if(visualization === QuestConstants.HSCVisualizationSymptomTracker){
      component = quest.objectives
        .filter(objective => objective.type === (ObjectiveConstants.ObjectiveTypeSymptom) || ((objective.type === ObjectiveConstants.ObjectiveTypeSlider) && (objective.name === "Impact of symptoms on day")))
        .map((objective,index) => {
          let entriesArray = objective.entries && objective.entries.filter((aEntry) => aEntry.value !== null);
            return (<div key={index.toString()}>
                <ChartTitle objective={objective}/>
                <BarChart objectiveId={objective.id}
                          entries={entriesArray}
                          minYValue={objective.minimum_value}
                          maxYValue={objective.maximum_value}
                          startDate={startDate}
                          endDate={endDate}
                          onClickListener={this.onChartClickedListener.bind(this)}
                          style={{height: '250px'}}
                >
                </BarChart>
              </div>
            );
          }
        );
    }
    else if(visualization === QuestConstants.HSCVisualizationLineGraph){
      const questName = this.getQuestName(quest);
      const isStoolDiary = questName === "Stool Diary";

      if(isStoolDiary) {
        component = quest.objectives
          .filter(objective => objective.is_primary_hsc_visualization)
      } else {
        component = quest.objectives
          .filter(objective => objective.type === ObjectiveConstants.ObjectiveTypeVital)
      }

      component = component.map((objective, index) => {
        let entriesArray = objective.entries && objective.entries.filter((entry) => entry.text !== null || entry.value !== null);
          return (<div key={index.toString()}>
              <ChartTitle objective={objective}/>
                <LineChart
                  objectiveId={objective.id}
                  entries={entriesArray}
                  minYValue={objective.minimum_value}
                  maxYValue={objective.maximum_value}
                  startDate={startDate}
                  endDate={endDate}
                  onClickListener={this.onChartClickedListener.bind(this)}
                  style={{height: '250px'}}
                  tooltipType={isStoolDiary ? TOOLTIP_TYPES.TOTAL: TOOLTIP_TYPES.AVERAGE}
                  tooltipText={isStoolDiary ? objective.name: null}
                  hideMinYValue={isStoolDiary}
                />
            </div>
          );
        }
      );
    }
    else {
      let objective = quest.objectives.find((objective) => objective.is_primary_hsc_visualization);

      if((objective === undefined || objective === null) && quest.objectives.length > 0){
        objective = quest.objectives[0];
      }
      if(objective != null){
        let entriesArray = objective.entries ? objective.entries : [];
        
        if(quest.rotate_objectives){
          entriesArray = quest.objectives.flatMap(objective => objective.entries);
        }

        if(objective.type === ObjectiveConstants.ObjectiveTypeText) {
          entriesArray = entriesArray.filter((aEntry) => aEntry.text !== null);
        }
        else if(objective.type === ObjectiveConstants.ObjectiveTypeMultipleChoice) {
          entriesArray = entriesArray.filter((aEntry) => (aEntry.answers !== null || aEntry.value !== null));
        }
        else{
          entriesArray = entriesArray.filter((aEntry) => aEntry.value !== null);
        }
        if(visualization === QuestConstants.HSCVisualizationBarGraph){
          component = <BarChart
            objectiveId={objective.id}
            entries={entriesArray}
            startDate={startDate}
            endDate={endDate}
            onClickListener={this.onChartClickedListener.bind(this)}
          >
          </BarChart>;
        } else if(visualization === QuestConstants.HSCVisualizationStackedBarGraph){
          component = <StackedBarChart
            objectiveId={objective.id}
            entries={entriesArray}
            startDate={startDate}
            endDate={endDate}
            onClickListener={this.onChartClickedListener.bind(this)}
          >
          </StackedBarChart>;
        } else{
          component = <EntryIndicatorChart
            objectiveId={objective.id}
            entries={entriesArray}
            startDate={startDate}
            endDate={endDate}
            onClickListener={this.onChartClickedListener.bind(this)}
          >
          </EntryIndicatorChart>;
        }
        component = (
          <div>
            <div className="patient-data-chart-container">
              {component}
            </div>
          </div>
        );
      }
    }
    return component;
  }

  getQuestName(aQuest) {
    if(aQuest.translations && aQuest.translations.length) {
         const questEnTranslation = aQuest.translations.find(translation => translation.locale === "en")
         if (questEnTranslation) {
             return questEnTranslation.title;
         }
    }
    return aQuest.title;
  }
    

  buildReminderChartGroups(quest, startDate, endDate){
    let reminderTimes = quest.objectives.flatMap((objective) => objective.reminders).map(reminder => reminder.time).sort(function(a, b){return a - b});
    let uniqueReminderTimes = Array.from(new Set(reminderTimes));

    if(quest.objectives.find(objective => objective.reminders.length === 0)){
      uniqueReminderTimes.push(null);
    }
    return (
      <div>
        {
          uniqueReminderTimes.map((reminderTime, index) => {
            return <ReminderChartGroup
              key={index.toString()}
              quest={quest}
              reminderTime={reminderTime}
              startDate={startDate}
              endDate={endDate}
              onChartClickedListener={this.onChartClickedListener.bind(this)}
            >
            </ReminderChartGroup>;
          })
        }
      </div>
    );
  }

  onChartClickedListener(clickEvent){
    const {toggleResponsesList} = this.props;
    let responseId = clickEvent.responseId;
    let objectiveId = clickEvent.objectiveId;


    if(responseId && objectiveId){
      toggleResponsesList(this.props.quest, objectiveId, this.props.to, this.props.from);
    }
  }
  
  // Render
  
  render(){
    let {quest, patient, numberOfDateRangesBack} = this.props;
    let {collapsed} = this.state;
    
    let {chart, objective} = this.state;
    
    return (
      <div className="patient-profile-tool-container">
        <div className="graph-section-container">
          <div className='patient-panel-headings-container clickable'
               onClick={() => this.toggleCollapsed()}>
            <div className='section-control-button'>
              <img src={this.state.collapsed ? expandDownButton : collapseUpButton} alt="collapseUpButton"/>
              <div className="patient-quest-title">{quest.title}</div>
            </div>
          </div>
          {
            !collapsed &&
            ( patient && chart != null &&
              <div style={{paddingBottom: 20, paddingTop: 20}}>
                <div key={quest.id + '-' + objective.id + '-' + patient.id + '-' + numberOfDateRangesBack}
                     style={{padding: "0 20px 0 20px"}}>
                  {chart}
                </div>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}
