import React, {useState} from 'react';
import classNames from 'classnames';
import './LabeledTextInput.scss';
import Loading from "../../Loading/Loading";
import LabeledInfo from "../LabeledInfo/LabeledInfo";
import LabeledErrors from "../LabeledErrors/LabeledErrors";
import LabeledExtraButtons, {extraButtonsCount} from "../LabeledExtraButtons/LabeledExtraButtons";

const LabeledTextInput = ({className, type, name, value, label, handleSave, descriptionLabel, disabled, errorMessage,
                            infoBlurb, addButtonClicked, maxLength, placeholder, required, minValue, onFocus, onBlur,
                            autoComplete = 'on', popoverPlacement = "top", warningMessage, sanitizer, menuButtonClicked,
                            editButtonClicked, deleteButtonClicked, bold = true, loading = false, showSearchIcon = false}) => {
  
  // Instance Variables
  
  const [isFocused, setIsFocused] = useState(false);
  
  const inputFocused = () => {
    setIsFocused(true);
    
    if(onFocus) {
      onFocus()
    }
  };
  
  const inputBlurred = () => {
    setIsFocused(false);
    
    if(onBlur) {
      onBlur();
    }
  };
  
  const handleChange = (aEvent) => {
    aEvent.preventDefault();
    aEvent.stopPropagation();
    let { value } = aEvent.target;
    
    if(sanitizer){
      value = sanitizer(value);
      aEvent.target.value = value;
    }
    return handleSave(aEvent);
  };
  
  // Render
  
  return (
    <div className={'labeled-text-input-container ' + className}>
      <LabeledInfo bold={bold}
                   label={label}
                   required={required}
                   infoBlurb={infoBlurb}
                   description={descriptionLabel}
                   popoverPlacement={popoverPlacement}
                   menuButtonClicked={menuButtonClicked}
      />
      
      <div className="labeled-text-input-cell">
        <input className={classNames({
          'is-empty': !value,
          'is-focused': isFocused,
          'text-input-field': true,
          'text-input-field-inactive': disabled,
          'text-input-field-error': errorMessage,
          'text-input-field-warning': warningMessage
        })}
               min={(minValue && minValue.toString()) || ""}
               type={type || 'text'}
               name={name}
               style={{minHeight: '2.5em'}}
               value={(value && value.toString()) || ""}
               onBlur={inputBlurred}
               onFocus={inputFocused}
               disabled={disabled}
               onChange={handleChange}
               maxLength={maxLength}
               placeholder={placeholder}
               autoComplete={autoComplete}
        />
  
        <Loading className="labeled-text-input-loading"
                 style={{right:(extraButtonsCount(showSearchIcon, addButtonClicked, editButtonClicked, deleteButtonClicked) * 35 + 15).toString() +'px'}}
                 size={'mini'}
                 loading={loading}
                 showLabel={false}
        />
        
        <LabeledExtraButtons showSearchIcon={showSearchIcon}
                             addButtonClicked={addButtonClicked}
                             editButtonClicked={editButtonClicked}
                             deleteButtonClicked={deleteButtonClicked}
        />
      </div>
      
      <LabeledErrors errorMessage={errorMessage}
                     warningMessage={warningMessage}
      />
    </div>
  )
};

export default LabeledTextInput;
